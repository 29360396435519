body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-decorate {
  text-decoration: none;
  color: inherit;
}

a {
  color: #28ff00;
}

.gradient-button {
  color: rgba(255,255,255,0.9);
  background: linear-gradient(-45deg, #000000, #3b8d00, #000000);
  background-size: 300%;
  animation: animation 16s ease-in-out infinite;
}

.large-button-text {
  font-size: 1.3rem;
}

.gradient-button-inner {
  width: 100%;
  height: 100%;
  color: rgba(255,255,255,0.9);
  background: linear-gradient(-45deg, #000000, #3b8d00, #000000);
  background-size: 300%;
  animation: animation 16s ease-in-out infinite;
  position: absolute;
  z-index: -1;
  filter: blur(20px);
  opacity: 0.7;
}

@keyframes animation {
  0% {
    background-position: 0% 50%
  }
  50% {   
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}